/**
 * Independent time operation tool to facilitate subsequent switch to dayjs
 */
import dayjs from 'dayjs'

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const DATE_FORMAT = 'YYYY-MM-DD'

export function formatToDateTime(
  date: dayjs.Dayjs | undefined = undefined,
  format = DATE_TIME_FORMAT,
): string {
  return dayjs(date).format(format)
}

export function formatToDate(
  date: dayjs.Dayjs | undefined = undefined,
  format = DATE_FORMAT,
): string {
  return dayjs(date).format(format)
}

// 获取北京时间时间戳
export function getTimeByZone(timezone = 8, date) {
  // 本地时间距离（GMT时间）毫秒数
  const nowDate = !date ? new Date().getTime() : new Date(date).getTime()
  // 本地时间和格林威治时间差，单位分钟
  const offset_GMT = new Date().getTimezoneOffset()
  //  反推到格林尼治时间
  const GMT = nowDate + offset_GMT * 60 * 1000
  //  获取指定时区时间
  const targetDate = new Date(GMT + timezone * 60 * 60 * 1000)
  return targetDate.getTime()
}

export const dateUtil = dayjs
